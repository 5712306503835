import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .slick-list': {
      padding: '0 !important',
    },
    '& .slick-slide img': {
      height: '100%',
      transition: 'all 1s ease',
    },
    '& .slick-slide': {
      cursor: 'pointer',
      transition: 'box-shadow .3s, background-color .3s',
    },
    '& .slick-disabled': {
      opacity: theme.palette.action.disabledOpacity,
      pointerEvents: 'none',
    },
    '& .slick-prev, & .slick-next': {
      zIndex: '100',
      width: 'auto',
      height: 'auto',
      transition: 'opacity 0.5s ease-out',
    },
    '& .slick-prev > button, & .slick-next > button': {
      border: `1px solid ${theme.mixins.White()}`,
      width: pxToRem(46),
      height: pxToRem(46),
      transition: 'all 0.3s ease-in-out',
      [theme.breakpoints.up('lg')]: {
        border: '1px solid #000',
        width: pxToRem(84),
        height: pxToRem(84),
      },
      '& svg': {
        [theme.breakpoints.down('md')]: {
          width: theme.spacing(2),
          height: theme.spacing(2),
        },
      },
      '&:hover': {
        backgroundColor: theme.mixins.Black(300),
      },
    },
    '& .MuiIconButton-label': {
      color: theme.mixins.White(),
      [theme.breakpoints.up('lg')]: {
        color: theme.mixins.Black(),
      },
    },
    '& .slick-prev::before,& .slick-next::before': {
      content: "''",
    },
    '& .slick-arrow + .slick-list .contentOnTop': {
      top: 'calc(50% - 45px)',
    },
    '& .slick-prev': {
      top: 'auto',
      ...theme.mixins.left('auto'),
      transform: 'none',
      ...theme.mixins.right(theme.spacing(9.6)),
      bottom: theme.spacing(6.4),
      [theme.breakpoints.up('lg')]: {
        bottom: '9%',
        ...theme.mixins.right(theme.spacing(22)),
      },
    },
    '& .slick-next': {
      top: 'auto',
      ...theme.mixins.right(theme.spacing(2)),
      transform: 'none',
      bottom: theme.spacing(6.4),
      [theme.breakpoints.up('lg')]: {
        bottom: '9%',
        ...theme.mixins.right(theme.spacing(7.6)),
      },
    },
    '&.rtlClass': {
      '& .slick-prev::before,& [dir="ltr"] .slick-next::before': {
        content: "''",
      },
      '& .slick-prev': {
        ...theme.mixins.right(theme.spacing(3)),
        ...theme.mixins.left('auto'),
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.right(theme.spacing(10)),
          ...theme.mixins.left('auto'),
        },
      },
      '& .slick-next': {
        ...theme.mixins.right(theme.spacing(10)),
        ...theme.mixins.left('auto'),
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.right(theme.spacing(23)),
          ...theme.mixins.left('auto'),
        },
      },
    },
    '& picture': {
      '&::before': {
        content: '""',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'block',
        position: 'absolute',
        background: theme.mixins.Black(200),
        zIndex: 1,
      },
    },
  },
  componentWrapper: {
    ...theme.shape.componentSpacing,
  },
  componentAdjustment: {
    marginBottom: `-${pxToRem(1)}`,
  },
}));

export default useStyles;
