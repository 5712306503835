import React from 'react';
import get from 'lodash/get';
import clsx from 'clsx';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Picture from '../../atoms/Picture';
import Container from '../../molecules/Container';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import useStyles from './style';
import { Box } from '@material-ui/core';
import {
  pushAnaylyticsData,
} from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import aspectRatios from '../../../utils/AspectRatios';

const CarouselType2Card = props => {
  const {
    title,
    desktopImage,
    thumbnailImageDesktop,
    primaryCTA,
    description,
    secondaryCTA,
    slideHeight,
    SEOEnglishCTA1,
    SEOEnglishCTA2,
  } = props;

  const addAnalyticsInfo = (isPrimary) => {
    let obj;
    if (isPrimary) {
      const seoPrimaryCta = get(SEOEnglishCTA1, 'value', '');
      obj = {
        ...analyticsData.pageBodyInteraction.whereToGoLink,
        ...{
          label: seoPrimaryCta,
        },
      };
    } else {
      const seoSecondaryCta = get(SEOEnglishCTA2, 'value', '');
      obj = {
        ...analyticsData.pageBodyInteraction.backToHomeLinkClick,
        ...{
          label: seoSecondaryCta,
        },
      };
    }
    pushAnaylyticsData(obj);
  };

  const isWhiteColor = color => color === 'white' || color === '#ffffff';
  const classes = useStyles({ ...props, isWhiteColor });
  const {
    carouselType2Card,
    imageBackground,
    colorBackground,
    imageOnTop,
    contentOnTop,
    colorBasedOnParam,
    imageBackgroundWithHeight,
    btnWithBorder,
  } = classes;
  const isImageBackground = get(desktopImage, 'value.src', '');
  const isThumbnailImageDesktop = get(thumbnailImageDesktop, 'value.src', '');
  return (
    <Container className={carouselType2Card}>
      <div
        className={clsx(
          isImageBackground ? imageBackground : imageBackgroundWithHeight,
          isImageBackground && 'backImageLarge'
        )}
        style={{ height: slideHeight }}
      >
        {isImageBackground && (
          <Picture
            media={{
              image: desktopImage,
              mobileImage: desktopImage,
              aspectRatio: aspectRatios['1x1'],
            }}
            alternateAltText={title && title.value}
          />
        )}
      </div>
      <div className={colorBackground} />
      <div className={imageOnTop}>
        {isThumbnailImageDesktop && (
          <Picture
            media={{
              image: thumbnailImageDesktop,
              mobileImage: thumbnailImageDesktop,
              aspectRatio: aspectRatios['3x2'],
            }}
            alternateAltText={title && title.value}
          />
        )}
      </div>
      <div className={clsx(contentOnTop, 'contentOnTop')}>
        {title && title.value && (
          <Typography variant="h1" component="h2">
            <Text field={title} />
          </Typography>
        )}
        {description && description.value && (
          <Typography variant="h5" component="p">
            <Text field={description} />
          </Typography>
        )}
        {get(primaryCTA, 'value.text', '') && (
          <div
            className={
              isWhiteColor(get(primaryCTA, 'value.class', ''))
                ? colorBasedOnParam
                : btnWithBorder
            }
          >
            <Button
              onClick={() => addAnalyticsInfo(true)}
              component="span"
              buttonType="primary"
              field={primaryCTA}
              hasBorder={false}
            />
          </div>
        )}
        {get(secondaryCTA, 'value.text', '') && (
          <Box className={classes.redLinkBtn}>
            <Button
              onClick={() => addAnalyticsInfo(false)}
              component="span"
              buttonType="primary"
              field={secondaryCTA}
              isStartIcon={true}
              hasBorder={false}
            />
          </Box>
        )}
      </div>
    </Container>
  );
};

export default CarouselType2Card;
