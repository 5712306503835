import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  carouselType2Card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    direction: theme.direction,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      cursor: 'default',
    },
  },
  imageBackground: {
    height: '65%',
    width: '100%',
    display: 'block',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: '60%',
    },
  },
  imageBackgroundWithHeight: {
    width: '100%',
    marginBottom: `-${pxToRem(1)}`,
    background: props => get(props, 'backgroundColor.value'),
    [theme.breakpoints.up('lg')]: {
      width: '60%',
      ...theme.mixins.marginRight(`-${pxToRem(1)}`),
    },
  },
  colorBackground: {
    width: '100%',
    paddingBottom: theme.spacing(3),
    background: props =>
      get(props, 'backgroundColor.fields.colorCode.value', ''),
    [theme.breakpoints.up('lg')]: {
      width: '40%',
      paddingBottom: 0,
    },
  },
  imageOnTop: {
    position: 'absolute',
    height: 'auto',
    width: '80%',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    top: '50%',
    transform: 'translateY(-60%)',
    ...theme.mixins.left('calc(50%)'),
    zIndex: 10,
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      width: '51%',
      ...theme.mixins.left('calc(45%)'),
      transform: 'translateY(-50%)',
    },
  },

  contentOnTop: {
    position: 'absolute',
    width: 'calc(100% - 8%)',
    zIndex: 15,
    top: '50%',
    ...theme.mixins.left('calc(4%)'),
    color: props => get(props, 'fontColor.value'),
    transform: 'translateY(-50%)',
    '& h2': {
      marginBottom: pxToRem(8),
    },
    '& p': {
      ...theme.palette.common.ellipsis(6),
      paddingTop: pxToRem(16),
      marginBottom: pxToRem(23),
      letterSpacing: 0,
      [theme.breakpoints.up('lg')]: {
        ...theme.palette.common.ellipsis(4),
        fontSize: fontSize.s30Rem,
        paddingTop: pxToRem(40),
        marginBottom: pxToRem(40),
        width: 'calc(51%)',
      },
    },
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.left('calc(5%)'),
    },
  },
  btnWithBorder: {
    '& .MuiButton-root': {
      borderTop: theme.borders.divider,
      paddingTop: theme.spacing(2),
    },
  },
  colorBasedOnParam: {
    '& .MuiButton-root': {
      color: theme.mixins.White(),
      borderTopColor: theme.mixins.White(200),
      borderTop: theme.borders.divider,
      paddingTop: theme.spacing(2),

      '& .MuiButton-endIcon': {
        color: theme.mixins.Black(),
        backgroundColor: theme.mixins.White(),
      },
      '&:hover': {
        '& .MuiButton-endIcon': {
          backgroundColor: `${theme.mixins.Black()}`,
          '& svg': {
            color: `${theme.mixins.White()}`,
          },
        },
      },
    },
  },
  redLinkBtn: {
    ...theme.typography.body2,
    marginTop: theme.spacing(2),
    ...theme.mixins.marginLeft(`-${pxToRem(14)}`),
    [theme.breakpoints.up('lg')]: {
      fontSize: fontSize.s24Rem,
      marginTop: theme.spacing(6),
    },

    '& .MuiButton-root': {
      color: props =>
        props.isWhiteColor(get(props, 'secondaryCTA.value.class'))
          ? theme.mixins.White()
          : theme.mixins.AbuDhabiOrange(),
      backgroundColor: theme.palette.background.transparent,
      '& .MuiButton-startIcon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...theme.mixins.marginLR(pxToRem(8), pxToRem(8)),
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: borderRadius.circle,
        paddingTop: pxToRem(6),
        paddingBottom: pxToRem(6),
        ...theme.mixins.paddingLR(pxToRem(6), pxToRem(6)),
        color: props =>
          props.isWhiteColor(get(props, 'secondaryCTA.value.class'))
            ? theme.mixins.White()
            : theme.mixins.AbuDhabiOrange(),
        [theme.breakpoints.up('lg')]: {
          paddingTop: pxToRem(4),
          paddingBottom: pxToRem(4),
          ...theme.mixins.paddingLR(pxToRem(4), pxToRem(4)),
        },
      },
      '&:hover': {
        '& .MuiButton-startIcon': {
          color: props =>
            props.isWhiteColor(get(props, 'secondaryCTA.value.class'))
              ? theme.mixins.AbuDhabiOrange()
              : theme.mixins.White(),
          backgroundColor: props =>
            props.isWhiteColor(get(props, 'secondaryCTA.value.class'))
              ? theme.mixins.White()
              : theme.mixins.AbuDhabiOrange(),
        },
        '& .MuiButton-label': {
          color: props =>
            props.isWhiteColor(get(props, 'secondaryCTA.value.class'))
              ? theme.mixins.White(500)
              : theme.mixins.AbuDhabiOrange(500),
        },
      },
    },
  },
}));

export default useStyles;
