import React from 'react';
import {
  NextArrow,
  PrevArrow,
} from '../../../components/molecules/CustomArrows';

export const settings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: 'progressive',
  initialSlide: 0,
  nextArrow: <NextArrow color="default" />,
  prevArrow: <PrevArrow color="default" />,
  arrows: true,
};
